import React from "react";
import {Redirect, Switch} from "react-router";
import asyncComponent from "../../util/asyncComponent";
import {PropsRoute} from "../../util/routeRestriction";

const AsyncRecruiter = asyncComponent(() => import('./recruiter'));
const AsyncMarketer = asyncComponent(() => import('./marketer'));
// const AsyncRecruiterAdmin = asyncComponent(() => import('./admin/RecruitmentAdmin'));
const AsyncMarketerAdmin = asyncComponent(() => import('./admin/MarketingAdmin'));
const AsyncEngineersDashboard = asyncComponent(() => import('./engineer/index'));
const AsyncProjectCoordinatorDashboard = asyncComponent(() => import('./projectCoordinator/index'));
const AsyncContractDashboard = asyncComponent(() => import('./contract/index'));
const AsyncConsultantDashboard = asyncComponent(() => import('./consultant/index'));

const getComponent = (roles) => {
  var currentURL = window.location.href;

  if (roles.some(r => ['Recruiter'].indexOf(r) >= 0)){
    return AsyncRecruiter 
  }
  if (roles.some(r => ['Marketer'].indexOf(r) >= 0)){
    return AsyncMarketer
  }
  if (roles.some(r => ['Engineering'].indexOf(r) >= 0)){
    return AsyncEngineersDashboard
  }
  if (roles.some(r => ['Project Coordination'].indexOf(r) >= 0)){
    return AsyncProjectCoordinatorDashboard
  }
  if (roles.some(r => ['Contract'].indexOf(r) >= 0)){
    return AsyncContractDashboard
  }
  if (roles.some(r => ['Consultant'].indexOf(r) >= 0)){
    return AsyncConsultantDashboard
  }
  if (roles.some(r => ['Admin'].indexOf(r) >= 0)){
    // if(currentURL.includes("dashboard/recruitment")) {
    //   return AsyncRecruiterAdmin;
    // }
    // else 
    // if(currentURL.includes("dashboard/marketing")){
      // if(currentURL.includes("dashboard")){
      return AsyncMarketerAdmin;
    // }
    // else{
    //   return AsyncRecruiterAdmin;
    // }
  }
};

const Dashboard = (props) => (

  <Switch>
    <Redirect exact from={`${props.match.url}/dashboard`} to={`${props.match.url}`}/>
    <PropsRoute path={`${props.match.url}`} dispatch={props.dispatch} user={props.user} component={getComponent(props.user.groups)} />
    <PropsRoute path={`${props.match.url}/recruitment`} dispatch={props.dispatch} user={props.user} component={getComponent(props.user.groups)}/>
    <PropsRoute path={`${props.match.url}/marketing`} dispatch={props.dispatch} user={props.user} component={getComponent(props.user.groups)}/>
  </Switch>

);

export default Dashboard;
