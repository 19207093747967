export const departmentsConst = {
    'Finance': 'finance',
    'Human Resource': 'human_resource',
    'Information Technology': 'it',
    'Marketing': 'marketing',
    'Project Coordinator': 'pc',
    'Project Management': 'pm',
    'Recruitment': 'recruitment',
    'Project Coordination':'project_coordination',
    'Engineering':'engineering',
    'Contract':'contract',
};

export const groups = {
    'Engineering':'Engineering',
    'Project Coordination':'Project Coordination',
    'Recruitment': 'Recruiter',
    'Marketing': 'Marketer',
    'Human Resource': 'Human Resource',
    'Finance': 'Accountant',
    'Information Technology': 'IT',
    'Admin':'Admin',
    'Contract':'Contract',
    'Consultant':'consultant'
    //"Department":"Group" 
};

export const levelsConst = {
    "Admin": 1,
    "Sub Admin":2,
    "Floor Manager":3,
    'Principle Manager':4,
    'Manager':5,
    'Team Lead':6,
    'Associate Team Lead': 7,
    'Executive': 8,
    'Intern':9,
}

export const defaultPageListLimit = 20;

