import React from "react";
import {Redirect, Switch} from "react-router";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

// const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import(''));
const AsyncContractPerson = asyncComponent(() => import('./contractPerson/index'));
const AsyncContractPersonEdit = asyncComponent(() => import('./contractPerson/edit'));
const AsyncTimeSheet = asyncComponent(() => import('../engineer/timeSheet/index'));
const AsyncConsultantList = asyncComponent(() => import('./consultant/index'));

const Contract = (props) => (
  <Switch>
    <Redirect exact from={`${props.match.url}/contract`} to={`${props.match.url}`}/>
        <PropsRoute path={`${props.match.url}/contract-person/list`} dispatch={props.dispatch}
          component={AsyncContractPerson} user={props.user}/>
        <PropsRoute path={`${props.match.url}/contract-person/edit/:contractPersonId`} 
          dispatch={props.dispatch} component={AsyncContractPersonEdit} user={props.user}/>
        <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
          dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/>
        <PropsRoute path={`${props.match.url}/consultant/list`} dispatch={props.dispatch}
          component={AsyncConsultantList} user={props.user}/>

  </Switch>
);

export default Contract;