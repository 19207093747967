export const ADMIN = 'Admin';
export const MARKETER = 'Marketer';
export const RECRUITER = 'Recruiter';
export const SCREENER = 'Screener';
export const SUPER_ADMIN = 'Super Admin';
export const PROJECT_COORDINATION = 'Project Coordination';
export const ENGINEER = 'Engineering';
export const HUMAN_RESOURCE = 'Human Resource';
export const CONTRACT = 'Contract';
export const CONSULTANT = 'Consultant';

export const consultantStatus = [
  {
    display: "New",
    Key:"viewed",
    value: "n",
}, {
    display: "Pipeline",
    Key:"Pipeline",
    value: "pl",
},
 {
    display: "Selected",
    Key:"Selected",
    value: "s",
},
  {
    display: "On-board",
    Key:"On boarded",
    value: "h",
},{
    display: "On-bench",
    Key:"On-bench",
    value: "o",
},
{
    display: "On-project",
    Key:"On-project",
    value: "p",
},{
    display: "In training",
    key: "In training",
    value: "t",
},
{
    display: "Marketing",
    Key:"Marketing",
    value: "m",
},
// {
//   display: "Fired",
//   Key:"Fired",
//   value: "f",
// },
{
  display: "Rejected",
  Key:"Rejected",
  value: "r",
},
{
    display: "Left Company",
    Key:"Left Company",
    value: "l",
}
];

export const marketerConsultantStatus = [
//   {
//     display: "On-bench",
//     value: "o",
// },
{
  display: "Marketing",
  value: "m",
},
{
    display: "On-project",
    value: "p",
},
// {
//     display: "Left Company",
//     value: "l",
// }
];


export const genderData = [
  {
  display: 'Male',
  value: 'm'
},
  {
  display: 'Female',
  value: 'f'
}
];

export const booleanData = [
  {
  display: 'Yes',
  value: true
},
  {
  display: 'No',
  value: false
}
];


export const maritalStatus = [
  {
  display: 'Married',
  value: 'm'
},
  {
  display: 'Single',
  value: 'u'
},
  {
  display: 'Divorced',
  value: 'd'
},
  {
  display: 'Separated',
  value: 's'
},
  {
  display: 'Widow',
  value: 'w'
}
];


export const sourceOpts = [
  {
  display: 'Dice',
  value: 'Dice'
},
  {
  display: 'Monster',
  value: 'Monster'
},
  {
  display: 'LinkedIn',
  value: 'Linkedin'
},
  {
  display: 'Reference',
  value: 'Reference'
},
  {
  display: 'Other',
  value: 'Other'
}
];

export const workAuthOpts = [
  {
  display: 'CPT',
  value: 'CPT'
},
  {
  display: 'CPT-Full Time',
  value: 'CPT-Full Time'
},
  {
  display: 'OPT',
  value: 'OPT'
},
  {
  display: 'GC-EAD',
  value: 'GC-EAD'
},
  {
  display: 'GC',
  value: 'GC'
},
  {
  display: 'USC',
  value: 'USC'
},
  {
  display: 'H4-EAD',
  value: 'H4-EAD'
},
  {
  display: 'H1B',
  value: 'H1B'
},
{
display: 'Pending',
value: 'Pending'
}
];


export const screeningData = [
  {
  display: 'Selected',
  value: 's'
},
  {
  display: 'Rejected',
  value: 'r'
},
  {
  display: 'On Hold',
  value: 'h'
},
  {
  display: 'Not Available',
  value: 'n/a'
}
];


export const contactTypes = [
  {
  display: 'Billing',
  value: 'b'
},
  {
  display: 'Recruiter',
  value: 'r'
},
  {
  display: 'Time-sheet',
  value: 't'
},
  {
  display: 'Other',
  value: 'o'
}
];

export const submissionStatusOptions = [
  {
    name: 'All',
    value: 'null'
  },
  {
    name: 'Pending',
    value: 'p'
  },
  {
    name: 'Completed',
    value: 'c'
  },
  {
    name: 'Failed',
    value: 'f'
  }
];

export const active_InActiveOptions = [
  {
    name:'All',
    value:'null',
  },
  {
    name:'Active',
    value:'active',
  },
  {
    name:'Inactive',
    value:'inactive',
  }
]
