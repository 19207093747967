import IntlMessages from "util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";

import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
// import { employeeInhouseOffersList } from "../../appRedux/actions/Offer";
import moment from "moment";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";
import { fetchEmployees, fetchProjectCoordinatorsOffersList, fetchSupportPersonsList } from "../../appRedux/actions";
import { levelsConst } from "../../constants/Constants";

const ProjectCoordinatorSidebar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const currentDate = new Date();
  const defaultStartDate = moment(`${currentDate.getFullYear()}-${currentDate.getMonth()+1}-01`);
  const defaultEndDate = moment();  
  const dispatch = useDispatch();

  const keysToDeleteFromSession = ['pageSize','employeePageNo','profilePageNo','submissionPageNo',
    'assessmentPageNo','clientPagination','pocPageNo','ipPageNo','transferRequestPageNo','offersPageNo',
    'supportPersonsoffersPageNo','ongoingTablePageNumber','joiningPendingTablePageNumber']
    
  const fetchDashboardData = () => {
    dispatch(fetchProjectCoordinatorsOffersList({status:'j'}))
    dispatch(fetchProjectCoordinatorsOffersList({status:'o'}))
    dispatch(fetchSupportPersonsList({pagination:false}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchSupportPerson = () => {
    dispatch(fetchSupportPersonsList({pagination:true}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }
  const fetchProjectCoordinators = () => {
    dispatch(fetchEmployees(1 ,null ,'project_coordination'))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }


  return (
        <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">

          <Menu.Item key="project_coordination.recruitment">
            <Link to={"/username/dashboard".replace('username', user.username)}
              onClick={()=>fetchDashboardData()}> <span>
              <i className="icon icon-auth-screen"/><IntlMessages id="Dashboard"/></span>
            </Link>
          </Menu.Item>
            <Menu.Item key="project_coordination.support_person">
              <Link to={"/".concat(user.username.concat("/project_coordination/support_person"))} onClick={()=>fetchSupportPerson()}> 
              <i className="icon icon-card"/><span>Support Person</span></Link>
            </Menu.Item>
          { user && user.level && user.level <= levelsConst['Team Lead'] && 
          <Menu.Item key="project_coordination.projectcoordinators">
              <Link to={"/".concat(user.username.concat("/project_coordination/project_coordinators"))} 
              onClick={()=>fetchProjectCoordinators()}> <i className="icon icon-card"/>
              <span style={{ display: 'inline-block', lineHeight: 1, marginBottom: '0px', transform: 'translate(0px, 8px)', top: '10px'}} >
              Project <br></br> Coordinator</span>
              </Link>
          </Menu.Item>
            }
        </Menu>
);
};
export default ProjectCoordinatorSidebar;
